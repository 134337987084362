<template>
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      size="xl"
      no-close-on-backdrop
      :title="title"
      @ok="handleCreateATVSLDDN0102($event)"
      @hidden="resetData"
    >
      <my-tabs
        :listTabs="listTabs"
        defaultActive="user-edit-tab-account"
        typeCss="active-full"
        @setActive="setActive"
      >
      </my-tabs>
      <validation-observer ref="rule">
        <AllInformation
          v-if="isRendered('general')"
          id="general"
          refs="information"
          :type="type"
          :dataGeneral="dataGeneral"
          class="tab-pane"
          :class="{active:activeTab==='general'}"
        />
        <TableTotalAccident
          v-if="isRendered('accident')"
          id="accident"
          class="tab-pane"
          :submitReportId="submitReportId"
          :class="{active:activeTab==='accident'}"
        />
        <TableReasonAccident
          v-if="isRendered('reason')"
          id="reason"
          class="tab-pane"
          :submitReportId="submitReportId"
          :class="{active:activeTab==='reason'}"
        />
        <TableSubsidizeAccident
          v-if="isRendered('regulation')"
          id="regulation"
          class="tab-pane"
          :submitReportId="submitReportId"
          :class="{active:activeTab==='regulation'}"
        />
        <TableElementAccident
          v-if="isRendered('injury')"
          id="injury"
          class="tab-pane"
          :submitReportId="submitReportId"
          :class="{active:activeTab==='injury'}"
        />
        <TableJobAccident
          v-if="isRendered('job')"
          id="job"
          class="tab-pane"
          :submitReportId="submitReportId"
          :class="{active:activeTab==='job'}"
        />
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>

import {
  BModal,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyTabs from '@/components/tabs/MyTabs.vue'

export default {
  components: {
    BModal,
    ValidationObserver,
    MyTabs,
    AllInformation: () => import('./tags-ATVSLDDN0102/AllInformation.vue'),
    TableTotalAccident: () => import('./tags-ATVSLDDN0102/TableTotalAccident.vue'),
    TableReasonAccident: () => import('./tags-ATVSLDDN0102/TableReasonAccident.vue'),
    TableSubsidizeAccident: () => import('./tags-ATVSLDDN0102/TableSubsidizeAccident.vue'),
    TableElementAccident: () => import('./tags-ATVSLDDN0102/TableElementAccident.vue'),
    TableJobAccident: () => import('./tags-ATVSLDDN0102/TableJobAccident.vue'),
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      collectReportId: null,
      activeTab: null,
      listTabs: [
        {
          key: 'general',
          title: 'Thông tin chung',
          icon: 'InfoIcon',
          isRendered: false,
        },
        {
          key: 'accident',
          title: 'Tiêu chí tổng số tai nạn lao động',
          icon: 'FileIcon',
          isRendered: false,
        },
        {
          key: 'reason',
          title: 'Tiêu chí phân loại theo nguyên nhân xảy ra tai nạn',
          icon: 'FileIcon',
          isRendered: false,
        },
        {
          key: 'injury',
          title: 'Phân loại theo yếu tố gây chấn thương',
          icon: 'ListIcon',
          isRendered: false,
        },
        {
          key: 'job',
          title: 'Phân loại theo nghề nghiệp',
          icon: 'ListIcon',
          isRendered: false,
        },
        {
          key: 'regulation',
          title: 'Tiêu chí Tai nạn được hưởng trợ cấp theo quy định tại Khoản 2 Điều 39 Luật ATVSLĐ',
          icon: 'FileIcon',
          isRendered: false,
        },
      ],
      dataGeneral: {
        totalWorkerAccident: 0,
        totalWorkerDeath: 0,
        totalWorkerInjury: 0,
      },
      submitReportId: '',
    }
  },
  watch: {
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },

    async dataDetail(val) {
      if (val && this.type === 'edit') {
        this.dataGeneral = {
          totalWorkerAccident: this.dataDetail.totalWorkerAccident,
          totalWorkerDeath: this.dataDetail.totalWorkerDeath,
          totalWorkerInjury: this.dataDetail.totalWorkerInjury,
        }
        this.submitReportId = this.dataDetail.id
      }
    },

    activeTab(val) {
      if (val === null) {
        this.activeTab = 'general'
        this.listTabs[0].isRendered = true
      }
    },
  },

  async created() {
    if (this.$route.params.type) {
      this.activeTab = this.$route.params.type
      const tab = this.listTabs.find(x => x.key === this.activeTab)
      if (tab) tab.isRendered = true
    }
    if (this.activeTab === null) {
      this.activeTab = 'general'
      this.listTabs[0].isRendered = true
    }
  },
  methods: {

    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    // thay đổi active tab
    setActive(activeTab) {
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) tab.isRendered = true
    },

    handleCreateATVSLDDN0102(bvModalEvt) {
      const model = {
        ...this.dataGeneral,
        totalWorkerAccident: Number(this.dataGeneral.totalWorkerAccident),
        totalWorkerDeath: Number(this.dataGeneral.totalWorkerDeath),
        totalWorkerInjury: Number(this.dataGeneral.totalWorkerInjury),
      }
      this.$emit('handleCreateATVSLDDN0102', model)
    },

    resetData() {
      this.activeTab = null
      this.dataGeneral = {
        totalWorkerAccident: 0,
        totalWorkerDeath: 0,
        totalWorkerInjury: 0,
      }
    },
  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
