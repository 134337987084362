export default {
  LIST_SUBMIT_REPORT: 'SubmitReport/submitReports',
  CREATE_SUBMIT_REPORT: 'SubmitReport/submitReports',
  CREATE_SUBMIT_REPORT_FORM: 'SubmitReport/submit-report-form',
  DELETE_SUBMIT_REPORT: 'SubmitReport/delete-submit-report',
  DETAIL_SUBMIT_REPORT: 'SubmitReport/submitReports/',
  EDIT_SUBMIT_REPORT: 'SubmitReport/submitReports',
  SEND_SUBMIT_REPORT: 'SubmitReport/send-report',
  REVOKE_SUBMIT_REPORT: 'SubmitReport/revoke-report',
  LIST_SUBMIT_REPORT_TITLES: 'SubmitReport/list-submit-report-titles',
  CREATE_SUBMIT_REPORT_TITLES: 'SubmitReport/update-submit-report-title',
  LIST_SUBMIT_REPORT_FIRST_EVALUATION: 'SubmitReport/list-submit-report-first-evaluation',
  CREATE_SUBMIT_REPORT_FIRST_EVALUATION: 'SubmitReport/add-submit-report-first-evaluation',
  DELETE_SUBMIT_REPORT_FIRST_EVALUATION: 'SubmitReport/delete-submit-report-first-evaluation',
  LIST_SUBMIT_REPORT_ACCIDENT_TITLES: 'SubmitReport/list-submit-report-accident-titles',
  CREATE_SUBMIT_REPORT_ACCIDENT_TITLES: 'SubmitReport/update-submit-report-accident-title',
  LIST_SUBMIT_REPORT_ACCIDENT_ELEMENT: 'SubmitReport/list-submit-report-accident-element',
  DELETE_SUBMIT_REPORT_ACCIDENT_ELEMENT: 'SubmitReport/delete-submit-report-accident-element',
  CREATE_SUBMIT_REPORT_ACCIDENT_ELEMENT: 'SubmitReport/add-submit-report-accident-element',
  LIST_SUBMIT_REPORT_ACCIDENT_JOB: 'SubmitReport/list-submit-report-accident-job',
  DELETE_SUBMIT_REPORT_ACCIDENT_JOB: 'SubmitReport/delete-submit-report-accident-job',
  CREATE_SUBMIT_REPORT_ACCIDENT_JOB: 'SubmitReport/add-submit-report-accident-job',
  COMBOBOX_INJURY_REASON: 'InjuryElement/get-combobox-injury-element',
  COMBOBOX_TYPEOFPROFESSION: 'TypeOfProfession/get-combobox-treeview',

  // Tạo báo cáo từ file
  SUBMIT_REPORT: '/SubmitReport/submit-report-upload-file',
}
